<template>
	<div class="edimensajeFormHeader puiformheader">
		<v-row>
			<v-col cols="12" md="3" v-if="false">
				<pui-field :label="$t('header.edimensaje.idmensaje')" :value="getIdmensajeValue"></pui-field>
			</v-col>
			<v-col cols="12" md="2">
				<pui-field :label="$t('header.edimensaje.destipomensaje')" :value="getDestipomensajeValue"></pui-field>
			</v-col>
			<v-col cols="12" md="2">
				<pui-field :label="$t('header.edimensaje.sentido')" :value="getSentidoValue"></pui-field>
			</v-col>
			<v-col cols="12" md="2">
				<pui-field :label="$t('header.edimensaje.desestado')" :value="getDesestadoValue"></pui-field>
			</v-col>
			<v-col cols="12" md="2">
				<pui-field :label="$t('header.edimensaje.numreftransmisor')" :value="getNumreftransmisorValue"></pui-field>
			</v-col>
			<v-col cols="12" md="2">
				<pui-field :label="$t('header.edimensaje.numrefemisor')" :value="getNumrefemisorValue"></pui-field>
			</v-col>
			<v-col cols="12" md="2">
				<pui-field :label="$t('header.edimensaje.iddocagente')" :value="getIddocagenteValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'edimensaje-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'edimensaje'
		};
	},
	computed: {
		getIdmensajeValue() {
			return this.model && this.model.idmensaje ? this.model.idmensaje : '-';
		},
		getDestipomensajeValue() {
			return this.model && this.model.destipomensaje ? this.model.destipomensaje : '-';
		},
		getSentidoValue() {
			return this.model && this.model.sentido ? this.model.sentido : '-';
		},
		getDesestadoValue() {
			return this.model && this.model.desestado ? this.model.desestado : '-';
		},
		getNumreftransmisorValue() {
			return this.model && this.model.numreftransmisor ? this.model.numreftransmisor : '-';
		},
		getNumrefemisorValue() {
			return this.model && this.model.numrefemisor ? this.model.numrefemisor : '-';
		},
		getIddocagenteValue() {
			return this.model && this.model.iddocagente ? this.model.iddocagente : '-';
		},
	}
}
</script>
